import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import {
  Link,
} from 'react-admin';

import { formatDate } from 'admin/assets/js/lib/utils';
import InvoiceStatus from 'admin/assets/js/components/InvoiceStatus.jsx';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';
import Money from 'finance/assets/js/lib/Money';

const useStyles = makeStyles(() => ({
  column: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%',
  },
  fieldValue: {
    textAlign: 'right',
  },
  transactionStatusLabel: {
    color: '#FFF',
    marginLeft: '10px',
    border: 'none',
    width: '100px',
  },
}));

const CashoutRequestViewInvoices = ({ record }) => {
  const classes = useStyles();
  if (isEmpty(record)) {
    return null;
  }

  const { invoiceTotalAmount, invoiceTotalCurrency, deferredInvoicePayments } = record;
  const invoiceTotalMoney = new Money(invoiceTotalAmount, invoiceTotalCurrency);

  return (
    <Grid item lg={6} xs={12}>
      <Card variant="outlined">
        <CardHeader
          style={{ paddingBottom: 0, marginBottom: 0 }}
          title={(
            <Typography
              variant="subtitle1"
              style={{ fontWeight: 600 }}
            >
              Invoices
            </Typography>
          )}
        />
        <CardContent style={{ padding: 0 }}>
          <TableContainer component="div">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>ID</strong></TableCell>
                  <TableCell><strong>Date</strong></TableCell>
                  <TableCell><strong>Status</strong></TableCell>
                  <TableCell><strong>Amount</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deferredInvoicePayments.map(({ invoice }) => {
                  const invoiceAmountsPOJO = new InvoiceAmounts(invoice.amounts);
                  const invoiceOrgMoney = invoiceAmountsPOJO.getOrgMoney();
                  return (
                    <TableRow key={invoice.id}>
                      <TableCell component="th" scope="row">
                        <Link to={`/children_invoices?filter=%7B"parent_id"%3A${invoice.id}%7D`}>
                          {invoice.id}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {formatDate(invoice?.createdAt)}
                      </TableCell>
                      <TableCell>
                        <InvoiceStatus
                          status={invoice.status}
                          raisedStatus={invoice.raisedStatus}
                          isOverdue={invoice.isOverdue}
                          onDemand={invoice.onDemand}
                        />
                      </TableCell>
                      <TableCell>
                        {invoiceOrgMoney.toString({
                          withSymbol: true, humanizeAmount: true,
                        })}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter
                style={{ backgroundColor: '#e8e8e8' }}
              >
                <TableRow>
                  <TableCell style={{ fontSize: 'inherit', color: 'inherit' }}>
                    <strong>Total</strong>
                  </TableCell>
                  <TableCell colSpan={2} />
                  <TableCell style={{ fontSize: 'inherit', color: 'inherit' }}>
                    {invoiceTotalMoney.toString({
                      withSymbol: true,
                      humanizeAmount: true,
                    })}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  );
};

CashoutRequestViewInvoices.propTypes = {
  record: PropTypes.object,
};

CashoutRequestViewInvoices.defaultProps = {
  record: {},
};

export default CashoutRequestViewInvoices;
