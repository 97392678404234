import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Link } from 'react-admin';

const useStyles = makeStyles({
  banner: {
    backgroundColor: '#369fdc',
    color: 'white',
    width: '100%',
    padding: '16px',
    borderRadius: '8px',
    marginBottom: '16px',
  },
});

const CashoutRequestBanner = ({ invoice }) => {
  const classes = useStyles();
  const { deferredInvoicePayment } = invoice;
  return deferredInvoicePayment && deferredInvoicePayment.cashoutRequestId ? (
    <div className={classes.banner}>
      <Info />
      {' The invoice is part of a provider Cashout Request '}
      <Link to={`/cashout_requests/${deferredInvoicePayment.cashoutRequestId}/show`}>
        #
        {deferredInvoicePayment.cashoutRequestId}
      </Link>
      {'. Some actions are unavailable and should be performed on the linked transaction details views.'}
    </div>
  ) : null;
};

CashoutRequestBanner.propTypes = {
  invoice: PropTypes.object,
};

CashoutRequestBanner.defaultProps = {
  invoice: {},
};

export default CashoutRequestBanner;
