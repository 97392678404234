import React from 'react';
import { Grid } from '@material-ui/core';

import CashoutRequestViewDetails
  from 'admin/assets/js/resources/cashout_requests/CashoutRequestViewDetails.jsx';
import CashoutRequestViewInvoices
  from 'admin/assets/js/resources/cashout_requests/CashoutRequestViewInvoices.jsx';
import CashoutRequestViewHeader
  from 'admin/assets/js/resources/cashout_requests/CashoutRequestViewHeader.jsx';
import CashoutRequestViewActions from 'admin/assets/js/resources/cashout_requests/CashoutRequestViewActions.jsx';

const CustomShowLayout = props => (
  <>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <CashoutRequestViewHeader {...props} />
      <CashoutRequestViewActions {...props} />
    </div>
    <Grid container item spacing={2}>
      <Grid container item spacing={2}>
        <CashoutRequestViewDetails {...props} />
      </Grid>
      <Grid container item spacing={2}>
        <CashoutRequestViewInvoices {...props} />
      </Grid>
    </Grid>
  </>
);

export default CustomShowLayout;
