import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

export const SelectInput = ({
  containerClassName,
  disabled,
  label: fieldLabel,
  onChange,
  options = [],
  required,
  value: fieldValue,
}) => {
  return (
    <FormControl className={containerClassName} disabled={disabled} required={required}>
      <InputLabel>{fieldLabel}</InputLabel>
      <Select onChange={onChange} value={fieldValue}>
        {options.map(({ label, value }) => <MenuItem key={value} value={value}>{label}</MenuItem>)}
      </Select>
    </FormControl>
  );
};

SelectInput.propTypes = {
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired, value: PropTypes.any.isRequired,
  })).isRequired,
  required: PropTypes.bool,
  value: PropTypes.any.isRequired,
};

SelectInput.defaultProps = {
  containerClassName: null,
  disabled: false,
  error: null,
  helperText: null,
  required: false,
};

export default SelectInput;
