import React from 'react';
import { useListContext } from 'react-admin';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import ListAsideMessage from 'admin/assets/js/resources/inbound_transfers/ListAsideMessage.jsx';
import TransactionAllocationForm from 'admin/assets/js/resources/inbound_transfers/TransactionAllocationForm.jsx';
import MapPayerForm from 'admin/assets/js/resources/inbound_transfers/MapPayerForm.jsx';
import OverviewTransferHeader from 'admin/assets/js/resources/inbound_transfers/OverviewTransferHeader.jsx';
import Money from 'finance/assets/js/lib/Money';
import TransferwiseTransfer from 'services/assets/js/lib/TransferwiseTransfer';

const useStyles = makeStyles(theme => ({
  listContainer: {
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  alert: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    color: theme.palette.warning.main,
  },
  alertContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
}));

const InboundTransferListAside = ({ onTransferChange }) => {
  const classes = useStyles();
  const context = useListContext();
  const location = useLocation();
  const { selectedTransferId } = qs.parse(location.search);
  if (!selectedTransferId) {
    return null;
  }
  const record = context?.data[selectedTransferId];

  if (!record) {
    return (
      <ListAsideMessage
        classes={classes.listContainer}
        muiIcon="pie_chart"
        title="Allocation details"
        body="Select a payment from the list to allocate the payment"
      />
    );
  }

  const {
    payerId,
    orgId,
    totalAllocatedAmount,
    ignored,
    fxMap,
    updatedAt,
  } = record;

  if (!payerId) {
    return (
      <ListAsideMessage
        classes={classes.listContainer}
        muiIcon="no_accounts"
        title="Unknown payer"
        body={`This payment ${record.id} has an unknown payer, please contact support`}
      />
    );
  }

  const transfer = new TransferwiseTransfer(record.transfer);
  const remoteId = transfer.getRemoteId();

  // we will be using 'target' fields because this is a transfer TD received
  const currency = transfer.getTargetCurrency();
  const transferAmount = new Money(transfer.getTargetValue(), currency).toString();
  const paymentDate = transfer.getTransactionRaisedAt();

  // note - it's possible to over allocate, so >= rather than just ===
  const fullAllocationAchieved = new Money(
    totalAllocatedAmount, currency,
  ).gte(transferAmount);

  const key = `${selectedTransferId}_${updatedAt}`;

  return (
    <React.Fragment key={key}>
      <OverviewTransferHeader
        remoteId={remoteId}
        transferAmount={transferAmount}
        allocatedAmount={totalAllocatedAmount}
        currency={currency}
        paymentDate={paymentDate.toDate()}
        ignored={ignored}
      />
      <div className={classes.listContainer}>
        {!orgId ? (
          <MapPayerForm
            transferId={selectedTransferId}
            payerId={payerId}
            onPayerMapping={onTransferChange}
          />
        ) : (
          <TransactionAllocationForm
            fullAllocationAchieved={fullAllocationAchieved}
            transferAmount={transferAmount}
            transferAmountCurrency={currency}
            remoteId={remoteId}
            orgId={orgId}
            onTransferChange={onTransferChange}
            totalAllocatedAmount={totalAllocatedAmount}
            fxMap={fxMap}
          />
        )}
      </div>
    </React.Fragment>
  );
};

InboundTransferListAside.propTypes = {
  onTransferChange: PropTypes.func,
};

InboundTransferListAside.defaultProps = {
  onTransferChange: () => {},
};

export default InboundTransferListAside;
