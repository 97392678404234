/* eslint react/prop-types: "warn", import/prefer-default-export: "warn" */
import React from 'react';
import moment from 'moment';
import {
  List, Datagrid, Filter, TopToolbar,
  TextField, FunctionField, ReferenceField,
  SelectInput, ReferenceInput, TextInput,
  SimpleShowLayout, Show, TabbedShowLayout, Tab,
} from 'react-admin';
import { Icon } from '@material-ui/core';

import {
  SERVICE_ORDER_TYPE,
  WORKSHEET_STATUS_LABEL,
  SERVICE_ORDER_TYPE_LABEL,
  WORKSHEET_STATUS,
} from 'projects/assets/js/constants';
import { formatDate } from 'admin/assets/js/lib/utils';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { CURRENCY_SYMBOL, API_DATE_FORMAT } from 'core/assets/js/constants';
import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import {
  MAX_ORGANIZATIONS_FETCHED,
  USER_ADMIN_ROLES,
  USER_ADMIN_ROLES_LABEL,
} from 'admin/assets/js/constants';
import { Link } from 'react-router-dom';
import { ORGANIZATION_STATUS } from 'organizations/assets/js/constants';

const statusOptions = Object.keys(WORKSHEET_STATUS_LABEL).map(k => ({
  id: k, name: WORKSHEET_STATUS_LABEL[k],
}));

const typeOptions = Object.keys(SERVICE_ORDER_TYPE_LABEL).map(k => ({
  id: k, name: SERVICE_ORDER_TYPE_LABEL[k],
}));

const BillablesShowActions = ({ permissions, data, resource }) => {
  return (
    <TopToolbar>
      { data && data.id && (
        <BulkApiActionButton
          disabled={data.status === WORKSHEET_STATUS.VOID}
          label="Mark as void"
          title="Mark as void"
          content={`Are you sure you want to mark this ${SERVICE_ORDER_TYPE_LABEL[data.type]} as void? Funds will be refunded to the project`}
          icon={<Icon>undo</Icon>}
          ids={[data.id]}
          resource={resource}
          action="mark-as-void"
        />
      )}
      { data && data.id && permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER] && (
        <BulkApiActionButton
          label="Include in last billing period"
          title="Include in last billing period"
          content={`Are you sure you want to include this ${SERVICE_ORDER_TYPE_LABEL[data.type]} in the last billing period?`}
          icon={<Icon>queue</Icon>}
          ids={[data.id]}
          resource={resource}
          disabled={data.status !== WORKSHEET_STATUS.APPROVED}
          action="include-billing-period"
        />
      )}
    </TopToolbar>
  );
};

const ListFilter = props => (
  <Filter
    {...props}
  >
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput
      label="Status"
      alwaysOn
      allowEmpty
      source="status"
      choices={statusOptions}
    />
    <ReferenceInput
      alwaysOn
      allowEmpty
      label="Organization"
      source="organization_id"
      reference="organizations_lite"
      perPage={MAX_ORGANIZATIONS_FETCHED}
      filter={{ status: ORGANIZATION_STATUS.ACTIVE }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectInput optionText={org => `${org.name} (${org.unique_alias})`} />
    </ReferenceInput>
    <SelectInput
      label="Type"
      source="type"
      alwaysOn
      choices={typeOptions}
    />
  </Filter>
);

const BillablesDataGrid = props => (
  <Datagrid>
    <ReferenceField
      {...props}
      source="id"
      label="ID"
      reference="service_orders"
      link="show"
    >
      <TextField source="id" />
    </ReferenceField>
    <ReferenceField
      {...props}
      source="organization_id"
      reference="organizations_lite"
      link={false}
    >
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField
      {...props}
      source="provider_id"
      reference="users"
      link={false}
      label="Provider email"
    >
      <FunctionField
        label="Provider email"
        render={user => (
          <Link to={`/full_users/${user.id}/show`}>{user.email}</Link>
        )}
      />
    </ReferenceField>
    <FunctionField
      source="status"
      label="Status"
      render={record => `${WORKSHEET_STATUS_LABEL[record.status]}${record.invoiced ? ' ( invoiced )' : ''}`}
      sortable={false}
    />
    <FunctionField
      source="amount"
      label="Amount"
      render={record => (
        <NumberTpl
          value={record.service_order_amount}
          prefix={CURRENCY_SYMBOL[record.service_order_currency]}
        />
      )}
      sortable={false}
    />
    <FunctionField
      source="created_at"
      label="Created at"
      render={record => formatDate(record.created_at)}
    />
    <FunctionField
      source="reviewed_at"
      label="Reviewed at"
      render={record => formatDate(record.reviewed_at)}
    />
  </Datagrid>
);

const BillableShow = props => {
  const { permissions } = props;
  return (
    <Show
      {...props}
      actions={<BillablesShowActions permissions={permissions} />}
    >
      <SimpleShowLayout>
        <FunctionField
          label=""
          addLabel={false}
          render={(record => {
            const isWsCurrencySameWithOrgCurrency = record?.details?.serviceOrderCurrency
              === record?.details?.organizationCurrency;
            return (
              <div>
                <div className="pb-2 mb-4" style={{ fontSize: '20px', borderBottom: '2px solid gray' }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <strong>{SERVICE_ORDER_TYPE_LABEL[record.type]}</strong>
                      {' '}
                      {`#${record.id}`}
                    </div>
                    <NumberTpl
                      style={{
                        fontSize: '20px',
                        backgroundColor: isWsCurrencySameWithOrgCurrency ? '#263076' : '#ff7300',
                        color: 'white',
                        padding: '5px 10px',
                        borderRadius: '5px 10px',
                      }}
                      prefix={CURRENCY_SYMBOL[record?.details?.serviceOrderCurrency]}
                      value={record?.details?.serviceOrderAmount}
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-sm-4">
                    <div className="mb-2" style={{ fontSize: '16px' }}>
                      <strong>Provider ID:</strong>
                      {' '}
                      {record.provider_id}
                    </div>
                    <div className="mb-2" style={{ fontSize: '16px' }}>
                      <strong>Provider email:</strong>
                      {' '}
                      <ReferenceField
                        {...props}
                        record={record}
                        source="provider_id"
                        reference="users"
                        link={false}
                      >
                        <TextField source="email" />
                      </ReferenceField>
                    </div>
                    <div className="mb-2" style={{ fontSize: '16px' }}>
                      <span style={{ color: isWsCurrencySameWithOrgCurrency ? 'black' : '#ff7300' }}>
                        <strong>Worksheet currency:</strong>
                      </span>
                      {' '}
                      {CURRENCY_SYMBOL[record?.details?.serviceOrderCurrency]}
                      {'/'}
                      {record?.details?.serviceOrderCurrency}
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="mb-2" style={{ fontSize: '16px' }}>
                      <strong>Project ID:</strong>
                      {' '}
                      {record.project_id}
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="mb-2" style={{ fontSize: '16px' }}>
                      <strong>Organization ID:</strong>
                      {' '}
                      {record.organization_id}
                    </div>
                    <div className="mb-2" style={{ fontSize: '16px' }}>
                      <strong>Organization alias:</strong>
                      {' '}
                      <ReferenceField
                        {...props}
                        record={record}
                        source="organization_id"
                        reference="organizations_lite"
                        link={false}

                      >
                        <TextField source="unique_alias" />
                      </ReferenceField>
                    </div>
                    <div className="mb-2" style={{ fontSize: '16px' }}>
                      <span style={{ color: isWsCurrencySameWithOrgCurrency ? 'black' : '#ff7300' }}>
                        <strong>Organization currency:</strong>
                      </span>
                      {' '}
                      {CURRENCY_SYMBOL[record?.details?.organizationCurrency]}
                      {'/'}
                      {record?.details?.organizationCurrency}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <TabbedShowLayout>
                      <Tab label="basic info">
                        <div className="mb-2">
                          <strong>Status:</strong>
                          {' '}
                          {WORKSHEET_STATUS_LABEL[record.status]}
                          {record.invoiced ? ' ( invoiced )' : ''}
                        </div>
                        <div className="mb-2">
                          <strong>Processed:</strong>
                          {' '}
                          {record.processed ? 'Yes' : 'No'}
                        </div>
                        <div className="mb-2">
                          <strong>Process at:</strong>
                          {' '}
                          {moment(record.process_at).format(API_DATE_FORMAT)}
                        </div>
                        <div className="mb-2">
                          <strong>Period:</strong>
                          {' '}
                          {record.period}
                        </div>
                        <div className="mb-2">
                          <strong>Created at:</strong>
                          {' '}
                          {formatDate(record.created_at)}
                        </div>
                        <div className="mb-2">
                          <strong>Updated at:</strong>
                          {' '}
                          {formatDate(record.updated)}
                        </div>
                        <div className="mb-2">
                          <strong>Reviewed at:</strong>
                          {' '}
                          {formatDate(record.reviewed_at)}
                        </div>
                      </Tab>
                      <Tab label="Additional info">
                        <div className="mb-2">
                          <strong>Summary:</strong>
                          {' '}
                          {record.summary || '-'}
                        </div>
                        <div className="mb-2">
                          <strong>Cancellation reason:</strong>
                          {' '}
                          {record.cancellation_reason || '-'}
                        </div>
                        <div className="mb-2">
                          <strong>Rejection reason:</strong>
                          {' '}
                          {record.rejection_reason || '-'}
                        </div>
                        <div className="mb-2">
                          <strong>Approval reason:</strong>
                          {' '}
                          {record.approval_reason || '-'}
                        </div>
                        <div className="mb-2">
                          <strong>Amendment reason:</strong>
                          {' '}
                          {record.amendment_reason || '-'}
                        </div>
                        <div className="mb-2">
                          <strong>Auto approved:</strong>
                          {' '}
                          {record.is_auto_approved ? 'Yes' : 'No'}
                        </div>
                      </Tab>
                    </TabbedShowLayout>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="pb-2 my-5" style={{ fontSize: '20px', borderBottom: '2px solid gray' }}>
                      <strong>PROVIDER&apos;S BILLABLES</strong>
                    </div>
                    <List
                      {...props}
                      key="service_orders"
                      filter={{
                        provider_id: record.provider_id,
                      }}
                      actions={null}
                      bulkActionButtons={false}
                      sort={{ field: 'id', order: 'DESC' }}
                    >
                      <BillablesDataGrid {...props} />
                    </List>
                  </div>
                </div>
              </div>
            );
          })}
        />
      </SimpleShowLayout>
    </Show>
  );
};

const Billables = (props) => {
  return (
    <List
      {...props}
      filters={<ListFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
      bulkActionButtons={false}
      filterDefaultValues={{ type: SERVICE_ORDER_TYPE.WORKSHEET }}
    >
      <BillablesDataGrid {...props} />
    </List>
  );
};


export {
  Billables as List,
  BillableShow as Show,
};
