import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';

import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import { TRANSACTION_STATUS } from 'finance/assets/js/constants';

const useStyles = makeStyles({
  container: {
    '& :not(:last-child)': {
      marginRight: '10px',
    },
  },
});

const CashoutRequestViewActions = ({ record }) => {
  const classes = useStyles();

  if (!record) {
    return null;
  }
  const { deferredInvoicePayments } = record;
  const firstDeferredInvoice = deferredInvoicePayments[0]?.invoice;
  const firstDeferredInvoiceTransaction = firstDeferredInvoice?.transactions?.[0];

  return (
    <div className={classes.container}>
      <BulkApiActionButton
        variant="outlined"
        size="medium"
        label="Recreate transaction"
        title="Recreate transaction"
        content="Are you sure you want to recreate a transaction for all invoices in the group?"
        ids={[firstDeferredInvoiceTransaction?.id]}
        resource="transactions"
        action="recreate_transactions"
        disabled={firstDeferredInvoiceTransaction?.status === TRANSACTION_STATUS.SUCCEEDED}
      />
    </div>
  );
};

CashoutRequestViewActions.propTypes = {
  record: PropTypes.object,
};

CashoutRequestViewActions.defaultProps = {
  record: {},
};

export default CashoutRequestViewActions;
