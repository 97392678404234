import React from 'react';
import { Link } from 'react-router-dom';
import {
  DateField,
  Datagrid,
  FunctionField,
  List,
  ShowButton,
} from 'react-admin';

import FactAuditRunsExpandedDetails from 'admin/assets/js/resources/fact_audit_runs/components/FactAuditRunsExpandedDetails.jsx';

/**
 * Show a list of fact_audit_run entities.
 * Each row represents a single audit run.
 *
 * Clicking the row takes you to a detail view for that run, this is the logs of the run.
 *
 * The row is expandable, and opens to reveal a summary of all orgs ids and log levels
 * in the selected run.
 *
 * @param {*} props - component properties.
 * @return {JSX.Element} rendered component.
 */
const FactAuditRunsList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: 'date', order: 'DESC' }}
  >
    <Datagrid
      isRowExpandable={() => true}
      expand={<FactAuditRunsExpandedDetails />}
    >
      <DateField label="Date (when audit was run)" source="date" showTime />
      <FunctionField
        label="User (who ran audit)"
        render={record => (
          <Link to={`/full_users/${record.userId}/show`}>{record.userName}</Link>
        )}
      />
      <DateField label="Billing process date" source="billingProcessDate" sortable={false} />
      <ShowButton />
    </Datagrid>
  </List>
);

export default FactAuditRunsList;
