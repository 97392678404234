import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  FormDataConsumer, TextInput,
  SelectInput, DateInput, SimpleForm,
} from 'react-admin';

import TransactionEditToolbar from 'admin/assets/js/components/transactions/TransactionEditToolbar.jsx';
import TransactionFormRelatedInfoComponent from 'admin/assets/js/components/transactions/TransactionFormRelatedInfoComponent.jsx';
import TransactionFormAmountsComponent from 'admin/assets/js/components/transactions/TransactionFormAmountsComponent.jsx';
import {
  TRANSACTION_METHOD_LABEL, INVOICE_TYPE,
  TRANSACTION_STATUS, TRANSACTION_TYPE, TRANSACTION_METHOD,
} from 'finance/assets/js/constants';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';
import TransactionAmounts from 'finance/assets/js/lib/TransactionAmounts';
import useExchangeRateHook from 'admin/assets/js/components/transactions/useExchangeRateHook';
import InvoicingSettings from 'finance/assets/js/lib/InvoicingSettings';
import { logger } from 'core/assets/js/lib/Logger';

export const parseInitialValues = ({ invoice }) => {
  if (isEmpty(invoice)) {
    return null;
  }
  const { type: invoiceType, amounts, id, number, orgId } = invoice;
  const invoiceAmounts = new InvoiceAmounts(amounts);
  const currency = invoiceAmounts.getCurrency();

  const ownerFE = invoice.ownerFE && new FinancialEntity(invoice.ownerFE);

  let serializedTransAmounts = {};
  try {
    const transactionAmounts = TransactionAmounts.fromInvoiceAmounts(
      invoiceAmounts,
    );
    serializedTransAmounts = transactionAmounts.serialize();
  } catch (err) {
    // TODO temp solution for unsuccessful amounts conversion
    logger.error(err);
  }
  return {
    countryCode: ownerFE?.getBankCountryCode() || 'GB',
    organization_id: orgId,
    invoice_id: id,
    invoice_number: number,
    amount: invoiceAmounts.getTotal(),
    currency,
    type: invoiceType === INVOICE_TYPE.OUTBOUND
      ? TRANSACTION_TYPE.INBOUND
      : TRANSACTION_TYPE.OUTBOUND,
    status: TRANSACTION_STATUS.PENDING,
    method: TRANSACTION_METHOD.WIRE_TRANSFER,
    date: moment().format('YYYY-MM-DD'),
    amounts: serializedTransAmounts,
    details: {
      amount: invoiceAmounts.getTotal(),
      currency,
      date: moment().format('YYYY-MM-DD'),
      invoiceNumber: number,
    },
    vendor_details: {},
  };
};

const methodOptions = Object.keys(TRANSACTION_METHOD_LABEL).map(k => ({
  id: parseInt(k, 10), name: TRANSACTION_METHOD_LABEL[k],
}));

const TransactionForm = ({
  resource, basePath, record, disabled, invoice, initialValues: predefinedInitialValues,
}) => {
  const exchangeRates = useExchangeRateHook();

  if (isEmpty(invoice) || !invoice.amounts) {
    return null;
  }

  const invoiceAmounts = new InvoiceAmounts(invoice.amounts);

  if (!(invoiceAmounts instanceof InvoiceAmounts)) {
    throw new Error('expected instance of InvoiceAmounts');
  }

  const transactionAmounts = record.amounts
    ? new TransactionAmounts(record.amounts)
    : TransactionAmounts.fromInvoiceAmounts(invoiceAmounts);

  const { type, isLicence, invoicingSettings } = invoice;
  const isOutbound = type === INVOICE_TYPE.OUTBOUND;
  const invoicingSettingsPOJO = new InvoicingSettings(invoicingSettings);
  const mode = invoicingSettingsPOJO.getMode();
  const showMsg = isOutbound && !isLicence;
  const invoiceDescriptor = `${invoice.number} (id: ${invoice.id})`;

  const initialValues = {
    invoiceDescriptor,
    amounts: {
      inBalanceCurrency: transactionAmounts.inBalanceCurrency,
    },
  };

  return (
    <SimpleForm
      resource={resource}
      basePath={basePath}
      initialValues={isEmpty(predefinedInitialValues) ? initialValues : {
        ...predefinedInitialValues,
        ...initialValues,
      }}
      defaultValues={initialValues}
      record={record}
      toolbar={
        disabled ? null : (
          <TransactionEditToolbar
            disabled={disabled}
            invoiceAmounts={invoiceAmounts}
            exchangeRates={exchangeRates}
          />
        )
      }
      redirect={false}
      variant="standard"
    >
      <TextInput
        disabled
        label="Invoice"
        source="invoiceDescriptor"
      />

      <SelectInput
        disabled={disabled}
        label="Transaction method"
        source="method"
        choices={methodOptions}
        fullWidth
      />

      <DateInput disabled={disabled} source="date" label="Date of transfer" fullWidth />

      <FormDataConsumer>
        {({ formData }) => (
          <>
            <TransactionFormRelatedInfoComponent
              disabled={disabled}
              formData={formData}
            />
            <TransactionFormAmountsComponent
              disabled={disabled}
              mode={mode}
              formData={formData}
              invoiceAmounts={invoiceAmounts}
              exchangeRates={exchangeRates}
            />
            {showMsg && (
              <p className="mt-3 mb-3">
                This is an OUTBOUND invoice, recording a transaction for this invoice
                will automatically generate transfers for all related INBOUND invoices
              </p>
            )}
          </>
        )}
      </FormDataConsumer>
    </SimpleForm>
  );
};

TransactionForm.propTypes = {
  resource: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  record: PropTypes.object,
  invoice: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  disabled: PropTypes.bool,
};

TransactionForm.defaultProps = {
  record: {},
  initialValues: {},
  disabled: false,
};

export default TransactionForm;
