import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { formatDate } from 'admin/assets/js/lib/utils';
import Money from 'finance/assets/js/lib/Money';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { CURRENCY_SYMBOL } from 'core/assets/js/constants';
import IgnoreButton from './IgnoreButton.jsx';

const useStyles = makeStyles(theme => ({
  overviewContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: '1px solid lightgray',
  },
  id: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.primary,
    width: '100%',
  },
  amounts: {
    display: 'flex',
    fontWeight: 600,
    color: theme.palette.secondary,
  },
  totalAmount: {
    color: theme.palette.primary,
    textAlign: 'left',
  },
}));

const OverviewTransferHeader = ({
  remoteId,
  paymentDate,
  allocatedAmount,
  currency,
  transferAmount,
  ignored,
}) => {
  const classes = useStyles();
  const transferAmountFormatted = new Money(transferAmount, currency)
    .toString();
  const allocatedAmountFormatted = new Money(allocatedAmount, currency)
    .toString();
  return (
    <>
      <div className={classes.overviewContainer}>
        <div className={classes.id}>
          <div>
            {'Wise transfer # '}
            {remoteId}
          </div>
          <IgnoreButton remoteId={remoteId} ignored={ignored} />
        </div>
      </div>
      <div className={classes.overviewContainer}>
        <div className={classes.amounts}>
          <NumberTpl
            value={allocatedAmountFormatted}
            prefix={CURRENCY_SYMBOL[currency]}
          />
          {'/'}
          <span className={classes.totalAmount}>
            <NumberTpl
              value={transferAmountFormatted}
              prefix={CURRENCY_SYMBOL[currency]}
            />
            {' '}
            allocated
          </span>
        </div>
        <div>
          {formatDate(paymentDate, 'YYYY/MM/DD')}
        </div>
      </div>
    </>
  );
};

OverviewTransferHeader.propTypes = {
  remoteId: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number,
  ]),
  paymentDate: PropTypes.oneOfType([
    PropTypes.string, PropTypes.instanceOf(Date),
  ]),
  currency: PropTypes.string,
  transferAmount: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number,
  ]),
  allocatedAmount: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number,
  ]),
  ignored: PropTypes.bool,
};

OverviewTransferHeader.defaultProps = {
  remoteId: null,
  paymentDate: '-',
  currency: null,
  transferAmount: null,
  allocatedAmount: null,
  ignored: false,
};

export default OverviewTransferHeader;
