import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  linkToRecord,
} from 'react-admin';

import { removeExtraSpaces } from 'core/assets/js/lib/utils';
import { useStyles } from 'admin/assets/js/layout/themes';
import Money from 'finance/assets/js/lib/Money';
import CustomTooltip from 'admin/assets/js/components/CustomToolTip.jsx';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';
import { TRANSACTION_MODE_RC_LABEL } from 'finance/assets/js/constants';

const CashoutListInvoiceDetails = ({ record }) => {
  const classes = useStyles();

  const invoiceAmounts = useMemo(() => record.invoices.reduce((map, inv) => {
    const amounts = new InvoiceAmounts(inv.frozen.amounts);
    // eslint-disable-next-line no-param-reassign
    map[inv.id] = amounts;
    return map;
  }, {}), [record]);

  const ownerFEName = useMemo(() => record
    .invoices[0].ownerFE.details.name,
  [record]);
  const ownerFEBankBeneficiary = useMemo(() => record
    .invoices[0].ownerFE.dimBankAccount.details.beneficiary,
  [record]);
  const bankCountry = useMemo(() => record
    .invoices[0].ownerFE.dimBankAccount.details.bankFields.address.country || '-',
  [record]);
  const subtotal = useMemo(() => record
    .invoices
    .reduce((runningTotal, inv) => runningTotal.add(invoiceAmounts[inv.id].getOrgNetValue()),
      new Money(0, invoiceAmounts[record.invoices[0].id].getOrgCurrency())),
  [record, invoiceAmounts]);
  const vat = useMemo(() => record
    .invoices
    .reduce((runningTotal, inv) => runningTotal.add(invoiceAmounts[inv.id].getOrgVat()),
      new Money(0, invoiceAmounts[record.invoices[0].id].getOrgCurrency())),
  [record, invoiceAmounts]);
  const total = useMemo(() => record
    .invoices
    .reduce((runningTotal, inv) => runningTotal.add(invoiceAmounts[inv.id].getOrgTotal()),
      new Money(0, invoiceAmounts[record.invoices[0].id].getOrgCurrency())),
  [record, invoiceAmounts]);
  const invDisplayNumber = useMemo(() => record
    .invoices.reduce((mapping, inv) => {
      // eslint-disable-next-line no-param-reassign
      mapping[inv.id] = inv.unique_number !== inv.number ? `${inv.number} (${inv.unique_number})` : inv.number;
      return mapping;
    }, {}),
  [record]);
  const netValues = useMemo(() => record.invoices.reduce((mapping, inv) => {
    const amounts = invoiceAmounts[inv.id];
    // eslint-disable-next-line no-param-reassign
    mapping[inv.id] = {
      inv: new Money(amounts.getNetValue(), amounts.getCurrency()),
      org: new Money(amounts.getOrgNetValue(), amounts.getOrgCurrency()),
    };
    return mapping;
  }, {}), [record, invoiceAmounts]);
  const contractorChargesOrgCurrency = useMemo(() => record.invoices.reduce((t, inv) => t
    .add(invoiceAmounts[inv.id].getOrgContractorCharges()),
  new Money(0, invoiceAmounts[record.invoices[0].id].getOrgCurrency())),
  [record, invoiceAmounts]);
  const contractorChargesAtInvoicingOrgCurrency = useMemo(() => record
    .invoices
    .reduce((runningTotal, inv) => runningTotal.add(invoiceAmounts[inv.id].getOrgNetValue()),
      new Money(0, invoiceAmounts[record.invoices[0].id].getOrgCurrency())),
  [record, invoiceAmounts]);
  const orgNetValue = useMemo(() => record.invoices.reduce((t, inv) => t
    .add(invoiceAmounts[inv.id].getOrgNetValue()),
  new Money(0, invoiceAmounts[record.invoices[0].id].getOrgCurrency())),
  [record, invoiceAmounts]);
  const hasDifferentBeneficiary = useMemo(() => (
    removeExtraSpaces(ownerFEName.toLowerCase())
    !== removeExtraSpaces(ownerFEBankBeneficiary.toLowerCase())
  ), [ownerFEName, ownerFEBankBeneficiary]);
  const transactionMode = TRANSACTION_MODE_RC_LABEL[record.transactionMode] || '-';

  return (
    <div className="on-demand-payouts__card">
      <div className="d-flex">
        <span className={classes.discreet}>Provider</span>
        <span className={`${classes.info} pl-4 ml-auto on-demand-payouts__card-details`}>
          {ownerFEName}
        </span>
      </div>
      <div className="d-flex">
        <span className={classes.discreet}>Beneficiary</span>
        <span className={`${hasDifferentBeneficiary ? classes.danger : classes.info} on-demand-payouts__card-details pl-4 ml-auto`}>
          {ownerFEBankBeneficiary}
        </span>
      </div>
      <div className="d-flex">
        <span className={classes.discreet}>Bank country</span>
        <span className={`${classes.info} pl-4 ml-auto on-demand-payouts__card-details`}>{bankCountry}</span>
      </div>
      <div className="d-flex">
        <span className={classes.discreet}>Service orders</span>
        <CustomTooltip
          placement="right"
          title="The accumulated total of all service orders"
        />
        <span className={`${classes.info} pl-4 ml-auto on-demand-payouts__card-details`}>
          {contractorChargesOrgCurrency.toString({ humanizeAmount: true, withSymbol: true })}
        </span>
      </div>
      {record.invoices.map(i => (
        <div className="d-flex">
          <span className={classes.discreet}>
            <Link to={linkToRecord('/invoices', i.id, 'edit')}>{invDisplayNumber[i.id]}</Link>
          </span>
          <span className={`${classes.info} on-demand-payouts__card-details pl-4 ml-auto`}>
            {
              (netValues[i.id].org.getCurrency() === netValues[i.id].inv.getCurrency())
                ? netValues[i.id].org.toString({ humanizeAmount: true, withSymbol: true })
                : `(${netValues[i.id].inv.toString({ humanizeAmount: true, withSymbol: true })}) ${netValues[i.id].org.toString({ humanizeAmount: true, withSymbol: true })}`
            }
          </span>
        </div>
      ))}
      <div className="d-flex">
        <span className={classes.discreet}>Subtotal</span>
        <span className={`${classes.info} pl-4 ml-auto on-demand-payouts__card-details`}>
          {subtotal.toString({ humanizeAmount: true, withSymbol: true })}
        </span>
      </div>
      <div className="d-flex">
        <span className={classes.discreet}>VAT</span>
        <span className={`${classes.info} pl-4 ml-auto on-demand-payouts__card-details`}>
          {vat.toString({ humanizeAmount: true, withSymbol: true })}
        </span>
      </div>
      <div className="d-flex">
        <span className={classes.discreet}>Total</span>
        <span className={`${classes.info} pl-4 ml-auto on-demand-payouts__card-details`}>
          {total.toString({ humanizeAmount: true, withSymbol: true })}
        </span>
      </div>
      <hr />
      <p className="on-demand-payouts__card-subtitle">Payment summary</p>
      <div className="d-flex">
        <span className={classes.discreet}>Transaction mode</span>
        <span className={`${classes.info} pl-4 ml-auto on-demand-payouts__card-details`}>
          {transactionMode}
        </span>
      </div>
      <div className="d-flex">
        <span className={classes.discreet}>Contractor charges on service order day</span>
        <span className={`${classes.info} pl-4 ml-auto on-demand-payouts__card-details`}>
          {contractorChargesOrgCurrency.toString({ humanizeAmount: true, withSymbol: true })}
        </span>
      </div>

      <div className="d-flex">
        <span className={classes.discreet}>Contractor charges on invoice day</span>
        <span className={`${classes.info} pl-4 ml-auto on-demand-payouts__card-details`}>
          {contractorChargesAtInvoicingOrgCurrency.toString({
            humanizeAmount: true,
            withSymbol: true,
          })}
        </span>
      </div>

      <div className="d-flex">
        <span className={classes.discreet}>Net amount invoiced to client</span>
        <span className={`${classes.info} pl-4 ml-auto on-demand-payouts__card-details`}>
          {orgNetValue.toString({ humanizeAmount: true, withSymbol: true })}
        </span>
      </div>
    </div>
  );
};

CashoutListInvoiceDetails.propTypes = {
  record: PropTypes.object.isRequired,
};

export default CashoutListInvoiceDetails;
