import React, { useMemo } from 'react';
import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  useGetOne,
} from 'react-admin';
import { Button, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import AuditLevel from 'admin/assets/js/resources/fact_audit_log_groups/AuditLevel';
import InvoiceAuditListModal from 'admin/assets/js/resources/fact_audit_log_groups/InvoiceAuditListModal';
import Money from 'finance/assets/js/lib/Money';

const useStyles = makeStyles({
  cta: {
    paddingTop: 6,
  },
});

const useGetOrgName = () => {
  // parse orgId from url query params
  const location = useLocation();
  const orgId = useMemo(() => {
    const { filter } = qs.parse(location.search);
    if (filter) {
      const parsedFilter = JSON.parse(filter);
      if (parsedFilter.orgId) {
        return parsedFilter.orgId;
      }
    }
    return null;
  }, [location]);

  // fetch org for id ( will not fetch for null )
  const { data } = useGetOne('organizations', orgId);

  // return name from org once fetched
  return data ? data.name : '';
};

const FactAuditLogsList = (props) => {
  const classes = useStyles();

  const orgName = useGetOrgName();

  return (
    <>
      <div className="d-flex justify-content-between my-4">
        <h2 className="my-0">
          {orgName ? `Audit of ${orgName} invoices` : 'Audit of invoices'}
        </h2>
        <Button className={classes.cta} color="primary" variant="contained">Re Run</Button>
      </div>
      <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        filterDefaultValues={{ billingProcessDate: '2024-09-30', orgId: '545' }}
        sort={{ field: 'created_at', order: 'DESC' }}
        actions={false}
      >
        <Datagrid hover={false}>
          <TextField source="groupDescriptor" label="Group" />
          <FunctionField
            label="Type"
            render={({ context }) => (
              <p>{context.invoiceType}</p>
            )}
          />
          <FunctionField
            label="Amount"
            render={({ context }) => (
              <p>{context.invoiceCurrency ? new Money(context.invoiceAmount, context.invoiceCurrency).toString({ withSymbol: true, humanizeAmount: true }) : '-'}</p>
            )}
          />
          <FunctionField
            label="Audit status"
            render={({ highestLevel, level }) => (
              <AuditLevel level={highestLevel || level} />
            )}
          />
          <FunctionField
            label=""
            render={(record) => (
              <>
                <InvoiceAuditListModal factAuditLog={record} />
              </>
            )}
          />
        </Datagrid>
      </List>
    </>
  );
};

export default FactAuditLogsList;
