import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useGetOne } from 'react-admin';
import Alert from '@material-ui/lab/Alert';

import InvoicesList from './InvoicesList.jsx';
import TransactionAttemptsList from './TransactionAttemptsList.jsx';

const TransactionAllocationForm = ({
  remoteId,
  transferAmount,
  transferAmountCurrency,
  fullAllocationAchieved,
  orgId,
  onTransferChange,
  totalAllocatedAmount,
  fxMap,
}) => {
  const { data: organization, loading: loadingOrg } = useGetOne('organizations_lite', orgId);

  if (loadingOrg) {
    return (<Typography variant="subtitle1">Loading invoices...</Typography>);
  }

  return (
    <>
      { fullAllocationAchieved
        ? (
          <Alert severity="success">
            <div style={{ marginBottom: '10px', textAlign: 'left' }}>
              <strong>This payment is fully allocated</strong>
            </div>
            <div>
              All money has been allocated to invoices.
            </div>
          </Alert>
        ) : (
          <InvoicesList
            fullAllocationAchieved={fullAllocationAchieved}
            transferAmount={transferAmount}
            transferAmountCurrency={transferAmountCurrency}
            remoteId={remoteId}
            organization={organization}
            totalAllocatedAmount={totalAllocatedAmount}
            fxMap={fxMap}
            onAllocation={onTransferChange}
          />
        )}
      <TransactionAttemptsList
        remoteId={remoteId}
        organization={organization}
        onUnallocation={onTransferChange}
      />
    </>
  );
};

TransactionAllocationForm.propTypes = {
  remoteId: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  transferAmount: PropTypes.string,
  transferAmountCurrency: PropTypes.string,
  orgId: PropTypes.number,
  fullAllocationAchieved: PropTypes.bool,
  onTransferChange: PropTypes.func,
  totalAllocatedAmount: PropTypes.string,
  fxMap: PropTypes.object,
};

TransactionAllocationForm.defaultProps = {
  remoteId: null,
  orgId: null,
  transferAmount: null,
  transferAmountCurrency: null,
  fullAllocationAchieved: false,
  onTransferChange: () => {},
  totalAllocatedAmount: '0.00',
  fxMap: {},
};

export default TransactionAllocationForm;
