import React from 'react';
import {
  ListBase,
  Pagination,
  TopToolbar,
  ListToolbar,
  ExportButton,
  useRefresh,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import ListFilter from './ListFilter.jsx';
import ListAside from './ListAside.jsx';
import DataGrid from './DataGrid.jsx';


const useStyles = makeStyles(() => ({
  aside: {
    textAlign: 'center',
    height: '100%',
  },
}));

const theme = createTheme({
  typography: {
    fontSize: 13,
  },
});

const themeAside = createTheme({
  typography: {
    fontSize: 13,
  },
});

const InboundTransferListContainer = (props) => {
  const refresh = useRefresh();
  const refreshSelected = () => refresh();

  const classes = useStyles();
  return (
    <ListBase
      {...props}
      sort={{ field: 'paymentDate', order: 'DESC' }}
      filterDefaultValues={{ status: 'unallocated' }}
    >
      <Grid container spacing={1}>
        <Grid item xs={7}>
          <ThemeProvider theme={theme}>
            <ListToolbar
              filters={<ListFilter />}
              actions={(
                <TopToolbar>
                  <ExportButton />
                </TopToolbar>
              )}
            />
            <Card>
              <DataGrid />
              <Pagination />
            </Card>
          </ThemeProvider>
        </Grid>
        <Grid item xs={5}>
          <ThemeProvider theme={themeAside}>
            <Card className={classes.aside}>
              <ListAside
                onTransferChange={(transferId) => {
                  refreshSelected(transferId);
                }}
              />
            </Card>
          </ThemeProvider>
        </Grid>
      </Grid>
    </ListBase>
  );
};

export default InboundTransferListContainer;
