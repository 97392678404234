import React from 'react';
import PropTypes from 'prop-types';

// admin
import { ReferenceManyField } from 'react-admin';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';

import TransactionDetailsList from 'admin/assets/js/resources/transactions/TransactionDetailsList.jsx';
import InvoiceAmountsField from 'admin/assets/js/resources/invoices/InvoiceAmountsField.jsx';
import InvoiceBankField from 'admin/assets/js/resources/invoices/InvoiceBankField.jsx';
import InvoiceOwnerFields from 'admin/assets/js/resources/invoices/InvoiceOwnerFields.jsx';
import InvoicingSettings from 'finance/assets/js/lib/InvoicingSettings';

const InvoiceTransferDetailsPanel = (props) => {
  const { record, isDeferred } = props;
  const invoicingSettings = new InvoicingSettings(record.invoicingSettings);
  const transactionMode = invoicingSettings.getTransactionMode();
  return (
    <>
      <Grid container item spacing={2}>
        <Grid item lg={6} xs={12}>
          <Card variant="outlined">
            <CardHeader
              title="Invoice Details"
              titleTypographyProps={{
                variant: 'h6',
              }}
            />
            <CardContent sx={{ m: 0, p: 0 }}>
              <InvoiceOwnerFields {...props} />
              { !record.ownerFE.isSystem && (
                <React.Fragment>
                  <hr />
                  <InvoiceBankField {...props} />
                </React.Fragment>
              )}
              <hr />
              <InvoiceAmountsField {...props} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Card variant="outlined">
            <CardHeader
              title="Transaction Details"
              titleTypographyProps={{
                variant: 'h6',
              }}
            />
            <CardContent sx={{ m: 0, p: 0 }}>
              <ReferenceManyField
                className="trw-transfers-ref"
                label=""
                addLabel={false}
                reference="transactions"
                target="invoice_id"
                sortable={false}
              >
                <TransactionDetailsList
                  transactionMode={transactionMode}
                  expectedAmount={record.amounts.total}
                  isDeferred={isDeferred}
                  className="d-flex"
                />
              </ReferenceManyField>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

InvoiceTransferDetailsPanel.propTypes = {
  record: PropTypes.object,
  isDeferred: PropTypes.bool,
};

InvoiceTransferDetailsPanel.defaultProps = {
  record: {},
  isDeferred: false,
};

export default InvoiceTransferDetailsPanel;
