/* eslint react/prop-types: "warn", import/prefer-default-export: "warn" */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Show, SimpleShowLayout, FunctionField, ReferenceField, TextField, useGetOne,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { isNil } from 'lodash';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';

import InvoiceTransferDetailsPanel from 'admin/assets/js/resources/invoices/InvoiceTransferDetailsPanel.jsx';
import {
  RAISED_FOR,
  INVOICE_TYPE,
  INVOICE_TYPE_LABEL,
} from 'finance/assets/js/constants';
import InvoiceOutboundAmountsPreview from 'admin/assets/js/resources/invoices/InvoiceOutboundAmountsPreview.jsx';
import InvoiceInboundAmountsPreview from 'admin/assets/js/resources/invoices/InvoiceInboundAmountsPreview.jsx';

import InvoiceTaxMethodField from 'admin/assets/js/resources/invoices/InvoiceTaxMethodField.jsx';
import InvoiceTransactionModeField from 'admin/assets/js/resources/invoices/InvoiceTransactionModeField.jsx';
import InvoiceEditActions from 'admin/assets/js/resources/invoices/InvoiceEditActions.jsx';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';
import InvoicingSettings from 'finance/assets/js/lib/InvoicingSettings';
import InvoiceStatus from 'admin/assets/js/components/InvoiceStatus';
import CashoutRequestBanner from 'admin/assets/js/resources/invoices/CashoutRequestBanner';

const useStyles = makeStyles(theme => ({
  form: {
    [theme.breakpoints.up('xs')]: {
      width: 400,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
    },
  },
  labelRow: {
    color: '#757575',
  },
  tableField: {
    display: 'block',
    float: 'left',
    minWidth: '50%',
    width: '100%',
  },
  totalRow: {
    backgroundColor: '#e8e8e8',
  },
  hoverableLink: {
    color: '#1584c6',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%',
  },
  fieldValue: {
    textAlign: 'right',
  },
}));


export const InvoiceInfoPanel = (props) => {
  const classes = useStyles();
  const { id: invoiceId, permissions, basePath } = props;

  const { data: invoiceData, loading, error } = useGetOne('invoices', invoiceId);
  if (loading) {
    return null;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <Show
      title="Invoice view"
      actions={(
        <InvoiceEditActions
          basePath={basePath}
          permissions={permissions}
          record={invoiceData}
        />
      )}
      component="div"
      {...props}
    >
      <SimpleShowLayout>
        <FunctionField
          label=""
          addLabel={false}
          className={classes.tableField}
          render={(record) => {
            const ownerFE = new FinancialEntity(record.ownerFE);
            const isOutBound = INVOICE_TYPE.OUTBOUND === record?.type;
            const parentId = record?.parentId;
            const invoicingSettings = new InvoicingSettings(record.invoicingSettings);
            return (
              <>
                <CashoutRequestBanner invoice={record} />
                <ReferenceField record={record} link="show" source="orgId" reference="organizations" {...props}>
                  <TextField source="name" style={{ fontSize: '20px', fontWeight: 600 }} />
                </ReferenceField>
                <div style={{ fontSize: '24px', marginBottom: '10px' }}>
                  {ownerFE.getName()}
                </div>
                <Grid container item spacing={2}>
                  <Grid item lg={6} xs={12}>
                    <Card variant="outlined">
                      <CardHeader
                        style={{ paddingBottom: 0, marginBottom: 0 }}
                        title={(
                          <ReferenceField
                            record={record}
                            source="id"
                            reference="invoices"
                            link="edit"
                            {...props}
                          >
                            <TextField source="number" style={{ fontSize: '16px', fontWeight: 600 }} />
                          </ReferenceField>
                        )}
                      />
                      <CardContent sx={{ m: 0, p: 0 }}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} className={classes.column}>
                            <Typography variant="subtitle2" color="textSecondary">
                              Invoice ID
                            </Typography>
                            <Typography variant="subtitle2">
                              {record.id}
                            </Typography>
                          </Grid>
                          { !isOutBound && (
                            <>
                              <Grid item xs={12} className={classes.column}>
                                <Typography variant="subtitle2" color="textSecondary">
                                  User Id
                                </Typography>
                                <Typography variant="subtitle2">
                                  <ReferenceField
                                    record={record}
                                    source="ownerId"
                                    reference="users"
                                    link={false}
                                    {...props}
                                  >
                                    <FunctionField
                                      label="User"
                                      render={user => (
                                        <Link
                                          to={`/full_users/${user.id}/show`}
                                        >
                                          {user.id}
                                        </Link>
                                      )}
                                    />
                                  </ReferenceField>
                                </Typography>
                              </Grid>
                            </>
                          )}
                          {
                            <Grid item xs={12} className={classes.column}>
                              <Typography variant="subtitle2" color="textSecondary">
                                Type
                              </Typography>
                              <Typography variant="subtitle2">
                                {`${INVOICE_TYPE_LABEL[record.type]}`}
                              </Typography>
                            </Grid>
                          }
                          {!isOutBound && parentId && (
                            <Grid item xs={12} className={classes.column}>
                              <Typography variant="subtitle2" color="textSecondary">
                                Outbound ID
                              </Typography>
                              <Typography variant="subtitle2">
                                <ReferenceField record={record} source="parentId" reference="invoices" link="edit" {...props}>
                                  <TextField source="number" />
                                </ReferenceField>
                                (
                                <ReferenceField record={record} source="parentId" reference="invoices" link="edit" {...props}>
                                  <TextField source="id" />
                                </ReferenceField>
                                )
                              </Typography>
                            </Grid>
                          )}

                          <Grid item xs={12} className={classes.column}>
                            <Typography variant="subtitle2" color="textSecondary">
                              Publication date
                            </Typography>
                            <Typography variant="subtitle2">
                              {record.date}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.column}>
                            <Typography variant="subtitle2" color="textSecondary">
                              Creation date
                            </Typography>
                            <Typography variant="subtitle2">
                              {record.createdAt}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.column}>
                            <Typography variant="subtitle2" color="textSecondary">
                              Due date
                            </Typography>
                            <Typography variant="subtitle2">
                              {record.dueAt}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.column}>
                            <Typography variant="subtitle2" color="textSecondary">
                              Charged to
                            </Typography>
                            <Typography variant="subtitle2">
                              {record.raisedFor === RAISED_FOR.TALENTDESK
                                ? 'TalentDesk.io'
                                : 'Client'
                              }
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.column}>
                            <Typography variant="subtitle2" color="textSecondary">
                              Tax method
                            </Typography>
                            <Typography variant="subtitle2">
                              <InvoiceTaxMethodField record={record} />
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.column}>
                            <Typography variant="subtitle2" color="textSecondary">
                              Invoicing mode
                            </Typography>
                            <Typography variant="subtitle2">
                              {invoicingSettings.getMode()}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.column}>
                            <Typography variant="subtitle2" color="textSecondary">
                              Transaction mode
                            </Typography>
                            <Typography variant="subtitle2">
                              <InvoiceTransactionModeField record={record} />
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.column}>
                            <Typography variant="subtitle2" color="textSecondary">
                              Status
                            </Typography>
                            <InvoiceStatus
                              status={record.status}
                              raisedStatus={record.raisedStatus}
                              isOverdue={record.isOverdue}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Card variant="outlined">
                      <CardHeader
                        title="Invoice Breakdown"
                        titleTypographyProps={{
                          variant: 'h6',
                        }}
                      />
                      <CardContent sx={{ m: 0, p: 0 }}>
                        {!isOutBound && (
                          <InvoiceInboundAmountsPreview
                            classes={classes}
                            record={record}
                            {...props}
                          />
                        )}
                        {isOutBound && (
                          <InvoiceOutboundAmountsPreview
                            record={record}
                            classes={classes}
                            {...props}
                          />
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </>
            );
          }}
        />
        <InvoiceTransferDetailsPanel
          {...props}
          classes={classes}
          isDeferred={!isNil(invoiceData.deferredInvoicePayment)}
        />
      </SimpleShowLayout>
    </Show>
  );
};

InvoiceInfoPanel.propTypes = {
  permissions: PropTypes.object,
  id: PropTypes.number,
  basePath: PropTypes.string,
};

InvoiceInfoPanel.defaultProps = {
  permissions: {
    role: null,
  },
  id: null,
  basePath: null,
};

export default InvoiceInfoPanel;
