import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { Tab, Tabs } from '@material-ui/core';

import ClassificationQuestionnaire from 'admin/assets/js/components/classification/ClassificationQuestionnaire.jsx';
import ClassificationSubmissions from 'admin/assets/js/components/classification/ClassificationSubmissions.jsx';
import { CLASSIFICATION_TABS } from 'admin/assets/js/constants';

const ClassificationView = () => {
  const history = useHistory();
  const location = useLocation();
  const { tab = CLASSIFICATION_TABS.RESPONSES } = qs.parse(location.search);
  return (
    <>
      <Tabs
        className="mb-2"
        onChange={(event, newTab) => {
          history.push(`${location.pathname}?tab=${newTab}`);
        }}
        value={tab}
      >
        <Tab label="Responses" value={CLASSIFICATION_TABS.RESPONSES} />
        <Tab label="Questionnaire" value={CLASSIFICATION_TABS.QUESTIONNAIRE} />
      </Tabs>
      {tab === CLASSIFICATION_TABS.RESPONSES && <ClassificationSubmissions />}
      {tab === CLASSIFICATION_TABS.QUESTIONNAIRE && <ClassificationQuestionnaire />}
    </>
  );
};

export default ClassificationView;
