/* eslint max-len: "off", react/no-multi-comp: "off" */
import React from 'react';
import PropTypes from 'prop-types';
import { BulkActionsToolbar } from 'react-admin';

import AllocationAggregatedInfo
  from 'admin/assets/js/resources/inbound_transfers/AllocationAggregatedInfo.jsx';
import AllocationButton from 'admin/assets/js/resources/inbound_transfers/AllocationButton.jsx';

const ReconciliationBulkActions = ({
  selectedIds,
  transferAmount,
  transferAmountCurrency,
  disabled,
  totalAllocatedAmount,
  amountToAllocate,
}) => (
  <div className="allocation-bulk-actions-container">
    <BulkActionsToolbar disabled={disabled}>
      <AllocationAggregatedInfo
        selectedIds={selectedIds}
        transferAmount={transferAmount}
        transferAmountCurrency={transferAmountCurrency}
        totalAllocatedAmount={totalAllocatedAmount}
        amountToAllocate={amountToAllocate}
      />
      <AllocationButton disabled={disabled} selectedIds={selectedIds} />
    </BulkActionsToolbar>
  </div>
);

ReconciliationBulkActions.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  transferAmount: PropTypes.string,
  transferAmountCurrency: PropTypes.string,
  disabled: PropTypes.bool,
  totalAllocatedAmount: PropTypes.string,
  amountToAllocate: PropTypes.string,
};

ReconciliationBulkActions.defaultProps = {
  selectedIds: [],
  transferAmount: null,
  transferAmountCurrency: null,
  disabled: false,
  totalAllocatedAmount: '0.00',
  amountToAllocate: '0.00',
};

export default ReconciliationBulkActions;
