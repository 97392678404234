/* eslint react/prop-types: "warn", import/prefer-default-export: "warn", react/no-multi-comp: 0 */
import React, { useCallback } from 'react';
import { get } from 'lodash';
import {
  SimpleForm, FormDataConsumer,
  SelectInput, NumberInput,
  Toolbar, SaveButton,
  ArrayInput, SimpleFormIterator,
  useNotify, useUpdate, useRefresh, useGetList,
} from 'react-admin';
import { Icon, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@material-ui/core';
import { useForm, useFormState } from 'react-final-form';
import { ICON, CURRENCY, CURRENCY_LABEL } from 'core/assets/js/constants';
import InvoicingFeeScheme from 'finance/assets/js/lib/InvoicingFeeScheme';
import LicenceFeeScheme from 'finance/assets/js/lib/LicenceFeeScheme';
import useStyles from './useStyles';
import { SERVICE_KEY_LABEL, SERVICE_KEY_NAME } from 'finance/assets/js/constants';
import ChildOrgBanner from 'admin/assets/js/resources/pricings/ChildOrgBanner';

const currencies = Object.values(CURRENCY).map(c => ({
  id: c, name: CURRENCY_LABEL[c],
}));

const SaveWithoutRedirectButton = ({ handleSubmitWithRedirect, ...props }) => {
  const formState = useFormState();
  const notify = useNotify();
  const refresh = useRefresh();
  const [update] = useUpdate('pricings', get(formState.values, 'id'));

  const handleClick = useCallback(() => {
    if (!formState.valid) {
      return;
    }

    const licenceScheme = LicenceFeeScheme.fromFormValues(formState.values);

    const data = {
      invoicing_fee_scheme: {
        processing: formState.values.scheme.getProcessingFeeSpec(),
        licence: licenceScheme.serialize(),
      },
    };

    update({ payload: { data } }, {
      onSuccess: () => {
        notify('ra.notification.updated', 'info', {
          smart_count: 1,
        });
        refresh();
      },
    });
  }, [
    formState.valid,
    formState.values,
    update,
    notify,
  ]);

  return <SaveButton {...props} redirect={false} handleSubmitWithRedirect={handleClick} />;
};

const ToolbarWithoutRedirect = props => (
  <Toolbar {...props}>
    <SaveWithoutRedirectButton />
  </Toolbar>
);

const LicenceFeesForm = (props) => {
  const { record: { id, invoicing_fee_scheme, invoicingParentOrg } } = props;
  const scheme = new InvoicingFeeScheme(invoicing_fee_scheme);
  const licenceSchemeSpec = scheme.getLicenceFeeSpec();
  const licenceScheme = scheme.getLicenceScheme();

  const initialAdditionalServices = licenceScheme.getAdditionalServicesFormValues();

  const { isLoading, data } = useGetList(
    'invoicing_entities',
    { page: 1, perPage: 10 },
    { field: 'created_at', order: 'DESC' },
  );

  const classes = useStyles();

  const isDisabled = !!invoicingParentOrg;

  if (isLoading) {
    return null;
  }

  const invoicingEntitiesChoices = Object.values(data).map(d => ({
    id: d.registration_number,
    name: d.name,
  }));

  const initialValues = {
    id,
    ...licenceSchemeSpec,
    scheme,
    additionalFormServices: initialAdditionalServices,
  };

  // The "record" we are handling here is a full organization. however,
  // the form is only interested in its licence fee scheme settings
  // if we use the original record as the "record" of the following form,
  // organization settings will override our form values
  // (e.g. organization.currency vs organization.invoicing_fee_scheme_dump.licence.currency)
  // So, we will use the part of the record that we are interested about,
  // this is just the values within initialValues
  // https://talentdesk.atlassian.net/browse/BT-2325?focusedCommentId=30185

  return (
    <SimpleForm
      record={initialValues}
      toolbar={<ToolbarWithoutRedirect />}
      redirect={false}
      initialValues={initialValues}
    >
      <FormDataConsumer>
        {({ formData }) => {
          const { change } = useForm();
          const hasDefinedService = (key) => {
            const licence = LicenceFeeScheme.fromFormValues(formData);
            return licence.hasDefinedFeeForServiceKey(key);
          };
          const selectNewAdditionalService = (key) => {
            const licence = LicenceFeeScheme.fromFormValues(formData);
            if (!licence.hasDefinedFeeForServiceKey(key)) {
              licence.setDefaultAdditionalService(key);
            } else {
              licence.removeAdditionalService(key);
            }
            const newAdditionalServices = licence.getAdditionalServicesFormValues();
            change('additionalFormServices', newAdditionalServices);
          };

          return (
            <>
              <ChildOrgBanner parent={invoicingParentOrg} />
              <div className={classes.containerClasses}>
                <NumberInput
                  disabled={isDisabled}
                  helperText="The minimum amount the client will be charged for manager seats each month."
                  step={0.01}
                  source="baseLicenceFee"
                  className={classes.itemClasses}
                  style={{ maxWidth: '50%' }}
                />
                <SelectInput
                  disabled={isDisabled}
                  helperText="The currency that licence fee prices are based on.  (By detault, USD pricing is used)."
                  label="Currency"
                  source="currency"
                  choices={currencies}
                  className={classes.itemClasses}
                  style={{ maxWidth: '50%', paddingRight: 0 }}
                />
              </div>
              <div className={classes.containerClasses}>
                <NumberInput
                  disabled={isDisabled}
                  helperText="Number of manager seats included in base fee."
                  step={0.01}
                  source="baseManagerSeats"
                  className={classes.itemClasses}
                  style={{ maxWidth: '25%' }}
                />
                <NumberInput
                  disabled={isDisabled}
                  helperText="Price per extra manager seat."
                  step={0.01}
                  source="perManagerSeat"
                  className={classes.itemClasses}
                  style={{ maxWidth: '25%' }}
                />
                <NumberInput
                  disabled={isDisabled}
                  helperText="Number of provider seats included in base fee."
                  step={0.01}
                  source="baseProviderSeats"
                  className={classes.itemClasses}
                  style={{ maxWidth: '25%' }}
                />
                <NumberInput
                  disabled={isDisabled}
                  helperText="Price per extra provider seat."
                  step={0.01}
                  source="perProviderSeat"
                  className={classes.itemClasses}
                  style={{ maxWidth: '25%', paddingRight: 0 }}
                />
              </div>
              <hr />

              <span>Select one of the following additional services for this organisation:</span>
              <List
                className="d-flex"
                component="nav"
                aria-label="main mailbox folders"
              >
                {Object.values(SERVICE_KEY_NAME).map(key => (
                  <ListItem
                    disabled={isDisabled}
                    button
                    key={key}
                    onClick={() => selectNewAdditionalService(key)}
                  >
                    <ListItemAvatar>
                      <Avatar variant="square" className={`${classes.large} ${!hasDefinedService(key) ? '' : classes.withValue}`}>
                        <Icon className={ICON.PERCENTAGE_ALT} fontSize="small" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={SERVICE_KEY_LABEL[key]}
                    />
                  </ListItem>
                ))}
              </List>

              <ArrayInput
                disabled={isDisabled}
                source="additionalFormServices"
                label="Additional services"
                className={classes.iterator}
              >
                <SimpleFormIterator
                  className={classes.formIterator}
                  disableAdd
                >
                  <SelectInput
                    key="service"
                    label="Service"
                    disabled
                    source="service"
                    choices={LicenceFeeScheme.getAdditionalServicesList()}
                    className={classes.itemClasses}
                  />
                  <SelectInput
                    key="systemReg"
                    label="Invoicing entity"
                    source="systemReg"
                    disabled
                    choices={invoicingEntitiesChoices}
                    className={classes.itemClasses}
                  />
                  <NumberInput
                    key="price"
                    step={0.5}
                    label="Price"
                    source="price"
                    className={classes.inputFields}
                    parse={value => parseFloat(value) || 0}
                    style={{ paddingRight: 0 }}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </>
          );
        } }
      </FormDataConsumer>
    </SimpleForm>
  );
};

export default LicenceFeesForm;
