import React from 'react';
import { Show } from 'react-admin';

import UserShowActions from 'admin/assets/js/resources/full_users/UserShowActions.jsx';
import UserInfo from 'admin/assets/js/resources/full_users/UserInfo.jsx';
import UserOrgInfoContainer from 'admin/assets/js/resources/full_users/UserOrgInfoContainer.jsx';

const CustomShowLayout = props => (
  <>
    <UserInfo {...props} />
    <UserOrgInfoContainer {...props} />
  </>
);

export const UserShow = (props) => (
  <Show
    actions={<UserShowActions />}
    component="div"
    {...props}
  >
    <CustomShowLayout />
  </Show>
);

export default UserShow;
