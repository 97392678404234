import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { AUDIT_LEVEL, AUDIT_LEVEL_COLORS } from 'finance/assets/js/constants';
import AuditBanner from 'admin/assets/js/resources/fact_audit_log_groups/AuditBanner';
import AuditLoader from 'admin/assets/js/resources/fact_audit_log_groups/AuditLoader';

const useStyles = makeStyles({
  button: {
    color: '#3f51b5',
  },
  pass: {
    color: AUDIT_LEVEL_COLORS[AUDIT_LEVEL.PASS],
    marginTop: -4,
  },
  fail: {
    color: AUDIT_LEVEL_COLORS[AUDIT_LEVEL.CRITICAL],
    marginTop: -4,
  },
  divider: {
    width: '100%',
    margin: '-4px 0 8px 0',
    padding: 0,
  },
  footer: {
    height: 32,
  },
});

const InvoiceAuditListModal = ({
  factAuditLog,
}) => {
  const classes = useStyles();
  const { groupIds, highestLevel, groupDescriptor } = factAuditLog;
  const [open, setOpen] = useState(false);
  const onOpen = useCallback(() => setOpen(true), [setOpen]);
  const onClose = useCallback(() => setOpen(false), [setOpen]);

  // note - ensure we only load when the dialog is open,
  //        else we'll preload everything all at once :/
  return (
    <>
      <IconButton
        onClick={onOpen}
        className={classes.button}
      >
        <VisibilityIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <div className="d-flex justify-content-between">
          <DialogTitle>
            {'Checklist for invoice '}
            {groupDescriptor}
            {' '}
            {highestLevel === AUDIT_LEVEL.PASS ? <CheckCircleIcon className={classes.pass} />
              : <CancelIcon className={classes.fail} />}
            {highestLevel === AUDIT_LEVEL.PASS ? ' Audit passed' : ' Audit failed'}
          </DialogTitle>
          <IconButton
            className="mr-2"
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <hr className={classes.divider} />
        <DialogActions className="mr-3">
          <Button
            color="primary"
            variant="outlined"
          >
            View invoice preview
          </Button>
          <Button
            color="primary"
            variant="contained"
          >
            Re Run
          </Button>
        </DialogActions>
        <AuditBanner level={highestLevel} />
        <DialogContent>
          <AuditLoader groupIds={groupIds} />
        </DialogContent>
        <div className={classes.footer} />
      </Dialog>
    </>
  );
};

InvoiceAuditListModal.propTypes = {
  factAuditLog: PropTypes.object.isRequired,
};

export default InvoiceAuditListModal;
