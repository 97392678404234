import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { Button, useGetList } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
  ICON,
  BS_STYLE,
  BS_SIZE,
  BS_TOOLTIP_PLACEMENT,
} from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import Dialog from '@material-ui/core/Dialog';
import TransactionForm, {
  parseInitialValues,
} from 'admin/assets/js/components/transactions/TransactionForm.jsx';
import AbbreviatedField from './AbbreviatedField.jsx';

const useTransactionFieldStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    '&:hover': {
      '& $createTransactionButton': {
        opacity: 1,
      },
    },
  },
  createTransactionButton: {
    opacity: 1,
    transitionDuration: '300ms',
    '& .btn-primary': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      padding: '8px',
      margin: '5px',
      '&:hover': {
        backgroundColor: 'transparent',
        borderColor: '#3F51B5',
        '& .fal': {
          color: 'blue',
        },
      },
    },
  },
}));

const TransactionModalField = ({
  resource, basePath, record: invoice,
}) => {
  if (isEmpty(invoice) || !invoice.amounts) {
    return null;
  }
  const [selected, setSelected] = useState(null);
  const initialValues = parseInitialValues({ invoice });
  const classes = useTransactionFieldStyles();
  const create = !isEmpty(get(selected, 'initialValues'));

  const { isLoading, data } = useGetList(
    'transactions',
    { page: 1, perPage: 100 },
    { field: 'id', order: 'DESC' },
    { invoice_id: invoice.id },
  );

  if (isLoading) {
    return null;
  }

  const transactions = Object.values(data);


  return (
    <div className={classes.container}>
      <div className={classes.createTransactionButton}>
        <TDButton
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setSelected({ initialValues });
          }}
          tooltipPosition={BS_TOOLTIP_PLACEMENT.TOP}
          bsSize={BS_SIZE.LARGE}
          variant={BS_STYLE.PRIMARY}
          btnIcon={ICON.ADD_CIRCLE}
          rounded
        />
      </div>
      <div>
        {transactions.map(t => (
          <Button
            key={t.id}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setSelected({ record: t });
            }}
          >
            <AbbreviatedField record={t} source="descriptor" />
          </Button>
        ))}
      </div>
      <Dialog
        open={!isEmpty(selected)}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        onClose={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setSelected(null);
        }}
      >
        <TransactionForm
          resource={resource}
          basePath={basePath}
          invoice={invoice}
          disabled={!create}
          {...selected}
        />
      </Dialog>
    </div>
  );
};

TransactionModalField.propTypes = {
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
};

export default TransactionModalField;
