import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  Button,
  useNotify,
} from 'react-admin';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form } from 'react-final-form';
import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';
import { formatDate } from 'admin/assets/js/lib/utils';

const DownloadNextInvoicesButton = ({ billingDeadline, record }) => {
  if (isEmpty(record)) {
    return null;
  }
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadId, setDownloadId] = useState(false);
  const notify = useNotify();
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const command = 'invoicing/download-next-invoices-for-org';
  const title = `Download next invoices for '${record.name}'`;

  const handleConfirm = async () => {
    const url = adminCommandApiUrl(command);
    setLoading(true);
    try {
      const res = await httpClient(url, { method: 'POST', body: JSON.stringify({
        orgId: record.id,
        billingDeadline,
      }) });
      setDownloadId(res.json.downloadId);
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
    }
  };

  const confirmMessage = `Do you want to generate a new export containing all invoices that will be generated for organization '${record.name}' on ${formatDate(billingDeadline)}`;

  return (
    <Fragment>
      <Button
        onClick={handleClick}
        label="Export next invoices"
      />
      <Dialog
        open={open}
        onClose={handleDialogClose}
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          {downloadId ? (
            <Link to={`/downloads/${downloadId}/show`}>See your download</Link>
          ) : (
            <Form
              onSubmit={handleConfirm}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <p>{confirmMessage}</p>
                  </div>
                  <div>
                    <Button variant="contained" disabled={loading} label="confirm" type="submit" />
                    <Button label="cancel" type="button" onClick={handleDialogClose} />
                  </div>
                </form>
              )}
            </Form>
          )}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

DownloadNextInvoicesButton.propTypes = {
  billingDeadline: PropTypes.string.isRequired,
  record: PropTypes.object,
};

DownloadNextInvoicesButton.defaultProps = {
  record: {},
};

export default DownloadNextInvoicesButton;
